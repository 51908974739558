<template>
  <div
    class="d-flex flex-column justify-center align-center"
    :style="computedHeight"
  >
    <GmapMap
      :center="origin"
      ref="mapRef"
      :zoom="15"
      :style="computedHeight"
      :clickable="true"
      :draggable="true"
      :zoomControl="true"
      :options="computedOptions"
      v-show="loadingMap"
    >
      <!-- Origen -->
      <GmapMarker
        ref="origin"
        :position="origin"
        :animation="2"
        :icon="markerOptionsOrigin"
      />
      <!-- Destination -->
      <GmapMarker
        ref="origin"
        :position="destination"
        :animation="2"
        :icon="markerOptionsDestination"
      />
      <GmapPolyline :path="path" :options="options" />
      <!-- provider -->
      <GmapCustomMarker
        :marker="{
          lat: trip.providerLocation[0],
          lng: trip.providerLocation[1],
        }"
        v-if="trip.providerLocation.length > 0 && trip.status !== 'Completed'"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <img
              :src="markerOptionsCarTest.url"
              alt=""
              :style="`transform: rotate(${trip.bearing}deg);`"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>
            <ul>
              <li>ID viaje: {{ trip.unique_id }}</li>
              <li v-if="trip.is_provider_status === 6">
                Usuario:
                {{ trip.user_first_name + " " + trip.user_last_name }}
              </li>
              <li>
                Conductor:
                {{ trip.provider_first_name + " " + trip.provider_last_name }}
              </li>
              <li>Status: {{ $t(trip.status) }} </li>
            </ul>
          </span>
        </v-tooltip>
      </GmapCustomMarker>
    </GmapMap>
    <v-progress-circular
      v-if="!loadingMap"
      indeterminate
      :size="64"
      color="primary"
    />
  </div>
</template>
<script>
import originIcon from "@/views/RunningTrips/assets/IconOriginMapMap.png";
import originIconParcel from "@/views/Parcel/assets/IconOriginMapMap.png";

import destinationIcon from "@/views/RunningTrips/assets/IconDestinationMapMap.png";
import destinationIconParcel from "@/views/Parcel/assets/IconDestinationMapMap.png";

import { gmapApi } from "vue2-google-maps";
import ellasxellas from "@/views/RunningTrips/assets/ELLASxELLAS.png";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { mdiMapMarker, mdiAccount } from "@mdi/js";

import nightMap from "@/views/RunningTrips/assets/nightMap";
export default {
  components: {
    GmapCustomMarker,
  },
  props: {
    origin: {
      type: Object,
    },
    destination: {
      type: Object,
    },
    path: {
      type: Array,
    },
    trip: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarker,
        mdiAccount,
      },
      centerCabled: { lat: 10.5041952, lng: -66.8858767 },
      loadingMap: false,
      markerOptionsOrigin: {
        url: this.trip.shipment ? originIconParcel : originIcon,
      },
      markerOptionsDestination: {
        url: this.trip.shipment ? destinationIconParcel : destinationIcon,
      },
      options: {
        strokeColor: "#EF4370",
        strokeOpacity: 0,
        geodesic: true,
        strokeWeight: 10,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              strokeWeight: 5,
              scale: 2.5,
            },
            offset: 0,
            repeat: "20px",
          },
        ],
      },
      markerOptionsCarTest: {
        url: ellasxellas,
      },
    };
  },
  computed: {
    google: gmapApi,
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return !this.formOpen
          ? "width: 100%; height: 500px"
          : "width: 100%; height: 700px";
      } else {
        return "width: 100%; height: 500px";
      }
    },
    computedOptions() {
      if (this.$vuetify.theme.isDark) {
        return {
          streetViewControl: false,
          styles: nightMap,
        };
      }
      if (!this.$vuetify.theme.isDark) {
        return { streetViewControl: false, styles: [] };
      }
    },
  },
  mounted() {
    if (this.$refs.mapRef) {
      this.$refs.mapRef.$mapPromise.then(() => {
        this.loadingMap = !this.loadingMap;
      });
    }
  },
  async created() {
    await new Promise((r) => setTimeout(r, 1200));
    let bounding = new this.google.maps.LatLngBounds();
    bounding.extend({
      lat: this.origin.lat,
      lng: this.origin.lng,
    });
    bounding.extend({
      lat: this.destination.lat,
      lng: this.destination.lng,
    });
    this.$refs.mapRef.fitBounds(bounding);
  },
};
</script>
<style lang="scss" scoped></style>
